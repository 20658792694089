@mixin checkBrowser() {
  html .check-browser-pop-up {
    position: fixed;
    display: block;
    z-index: 100;
    background: #ffff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    .btn-main {
      display: inline-block;
      background: #000;
      color: #fff;
      padding: 1rem;
      margin-bottom: 1rem;
    }

    .btn-other {
      display: block;
      color: #000;
      text-decoration: underline;
    }
  }

  .check-browser-pop-up__content {
    position: absolute;
    text-align: center;
    padding: 3rem;
    background: #fff;
    width: 40rem;
    max-width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
  }
}
